import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ReactTooltip from "react-tooltip"

const TestimonialsWrapper = styled.div`
  display: flex;
  background: ${({ page }) =>
    page === "home"
      ? "#fff"
      : "linear-gradient(42.57deg, #D9FCE2 0%, #9AE8E6 52.23%, #B7E2EE 93.37%)"};
  width: 100%;
  align-items: center;
`

const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 15px 20px;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  @media (max-width: 440px) {
    flex-wrap: wrap;
  }

  .__react_component_tooltip {
    background: ${({ page }) =>
      page === "home"
        ? "linear-gradient(42.57deg, #D9FCE2 0%, #9AE8E6 52.23%, #B7E2EE 93.37%)"
        : "#fff"};
    color: #262626;
    max-width: 300px;
    border-radius: 40px 0px;
    border-width: ${({ page }) => (page === "home" ? "0px" : "1px")};
    border-color: ${({ page }) => (page === "home" ? "inherit" : "#5db9bc")};
    padding: 20px;
  }

  .__react_component_tooltip h6 {
    margin: 0px 0px 10px 0px;
    font-family: "BebasNeueBold";
    font-size: 24px;
  }

  .__react_component_tooltip p {
    margin: 0px;
    font-family: "Gotham Book";
    font-size: 14px;
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
  .__react_component_tooltip.place-left:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
    border-left-color: ${({ page }) => (page === "home" ? "#5db9bc" : "#fff")};
    border-left-style: solid;
    border-left-width: 6px;
  }
  .__react_component_tooltip.place-right:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -6px;
    top: 50%;
    margin-top: -4px;
    border-right-color: ${({ page }) => (page === "home" ? "#5db9bc" : "#fff")};
    border-right-style: solid;
    border-right-width: 6px;
  }
  .__react_component_tooltip.place-top:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top-color: ${({ page }) => (page === "home" ? "#5db9bc" : "#fff")};
    border-top-style: solid;
    border-top-width: 6px;
  }
  .__react_component_tooltip.place-bottom:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: ${({ page }) =>
      page === "home" ? "#5db9bc" : "#fff"};
    border-bottom-style: solid;
    border-bottom-width: 6px;
  }
`

const TestimonialTitle = styled.div`
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 24px;
  }
`

const TestimonialSubtitle = styled.div`
  font-family: "Museo";
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;

  @media (max-width: 350px) {
    font-size: 14px;
  }
`

const Testimonials = styled.div`
  display: flex;
  width: 100%;
  max-width: 750px;
  justify-content: center;
  align-items: center;
  margin: 15px 0px 0px 0px;
`

const TestimonialBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .__react_component_tooltip {
    background: ${({ page }) =>
      page === "home"
        ? "linear-gradient(42.57deg, #a5fab7 0%, #00c4c1 52.23%, #50b5d5 93.37% )"
        : "#fff"};
    color: #262626;
    max-width: 300px;
    border-radius: 40px 0px;
    border-width: ${({ page }) => (page === "home" ? "0px" : "1px")};
    border-color: ${({ page }) => (page === "home" ? "inherit" : "#5db9bc")};
    padding: 20px;
  }

  .__react_component_tooltip h6 {
    margin: 0px 0px 10px 0px;
    font-family: "BebasNeueBold";
    font-size: 24px;
  }

  .__react_component_tooltip p {
    margin: 0px;
    font-family: "Gotham Book";
    font-size: 14px;
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
  .__react_component_tooltip.place-left:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
    border-left-color: ${({ page }) => (page === "home" ? "#5db9bc" : "#fff")};
    border-left-style: solid;
    border-left-width: 6px;
  }
  .__react_component_tooltip.place-right:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -6px;
    top: 50%;
    margin-top: -4px;
    border-right-color: ${({ page }) => (page === "home" ? "#5db9bc" : "#fff")};
    border-right-style: solid;
    border-right-width: 6px;
  }
  .__react_component_tooltip.place-top:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top-color: ${({ page }) => (page === "home" ? "#5db9bc" : "#fff")};
    border-top-style: solid;
    border-top-width: 6px;
  }
  .__react_component_tooltip.place-bottom:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: ${({ page }) =>
      page === "home" ? "#5db9bc" : "#fff"};
    border-bottom-style: solid;
    border-bottom-width: 6px;
  }
`

const TestimonialImage = styled.img`
  width: 100%;
  max-width: 45px;
  margin-bottom: 7px;
  object-fit: contain;
  cursor: pointer;
`

const DesktopTestimonialText = styled.div`
  font-family: "Museo";
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${({ page }) =>
    page === "home" ? "#00c4c1" : "#262626"};

  @media (max-width: 767px) {
    display: none;
  }
`

const MobileTestimonialText = styled.div`
  font-family: "Museo";
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
  display: none;
  text-decoration: underline;
  text-decoration-color: ${({ page }) =>
    page === "home" ? "#00c4c1" : "#262626"};

  @media (max-width: 767px) {
    display: block;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }

  @media (max-width: 360px) {
    font-size: 13px;
  }
`

const TestimonialSection = ({ intl, data, page }) => {
  return (
    data && (
      <TestimonialsWrapper page={page}>
        {data.list && (
          <TestimonialsContainer page={page}>
            {data.title && (
              <TestimonialTitle
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
            {data.subtitle && (
              <TestimonialSubtitle
                dangerouslySetInnerHTML={{
                  __html: data.subtitle,
                }}
              />
            )}
            <Testimonials>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <TestimonialBox page={page} key={index.toString()}>
                      {item.image && (
                        <TestimonialImage
                          className="lazyload"
                          data-src={item.image}
                          data-tip={item.popup_text}
                          data-html={true}
                          data-for={`testimonial_section` + index}
                        />
                      )}
                      {item.text && (
                        <DesktopTestimonialText
                          page={page}
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                          data-tip={item.popup_text}
                          data-html={true}
                          data-for={`testimonial_section` + index}
                        />
                      )}
                      {item.mobile_text && (
                        <MobileTestimonialText
                          page={page}
                          lang={intl.locale}
                          dangerouslySetInnerHTML={{
                            __html: item.mobile_text,
                          }}
                          data-tip={item.popup_text}
                          data-html={true}
                          data-for={`testimonial_section` + index}
                        />
                      )}
                      <ReactTooltip
                        id={`testimonial_section` + index}
                        html={true}
                        place={index == 0 ? "right" : "top"}
                      />
                    </TestimonialBox>
                  )
                )
              })}
            </Testimonials>
          </TestimonialsContainer>
        )}
      </TestimonialsWrapper>
    )
  )
}

export default injectIntl(TestimonialSection)
